import { useEventsStore } from '~/stores/events'
import { mapEvents, mapEvent } from '../mappers/event-mapper'
import { GUEST_PARTY_AUTH_COOKIE_KEY } from '../constants/cookies'
import { REJECT } from '../constants/reject_reasons'
import { useGuestStore } from "~/stores/guest"

/**
 * @typedef {EventService}
 * @alias this.$eventService
 */
export class EventService {
  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
    this.config = nuxtApp.$config.public
    this.route = useRoute()
    this.guestStore = useGuestStore()
  }

  init() {
    this.$apiService = this.nuxtApp.$apiService
    this.$clientCookieService = this.nuxtApp.$clientCookieService
  }


  /**
   * @param {momentshare.models.event.EventProperty[]} eventProperties
   * @param {string} key
   * @returns {momentshare.models.event.EventProperty|undefined}
   */
  getEventPropertyByKey(eventProperties, key) {
    if (!eventProperties) return

    return eventProperties.find(property => property.key === key)
  }

  /**
   * @returns {Promise<momentshare.models.event.Event[]>}
   */
  getUserEvents() {
    return this.$apiService.instance
      .get(`/event`)
      .then(response => mapEvents(response.data, this.config.filesPath))
  }

  /**
   * @returns {Promise<void>}
   */
  async fetchUserEvents() {
    const eventsStore = useEventsStore()
    const events = await this.getUserEvents()

    eventsStore.setEvents(events)
  }

  /**
   * @param {string} eventId
   * @returns {Promise<momentshare.models.event.Event>}
   */
  getEventAsUser(eventId) {
    return this.$apiService.instance
      .get(`/event/${eventId}/as-user`)
      .then(response => mapEvent(response.data, this.config.filesPath))
  }

  /**
   * @param {string} eventId
   * @param {Object} formData
   * @returns {Promise}
   */
  updateEvent(eventId, formData) {
    return this.$apiService.instance.patch(`/event/${eventId}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
  }

  /**
   * @param {string} eventId
   * @returns {Promise}
   */
  deleteEvent(eventId) {
    return this.$apiService.instance.delete(`/event/${eventId}`)
  }

  /**
   * @param {string} eventId
   * @param {string|null} [token]
   * @returns {Promise<momentshare.models.event.Event>}
   */
  async getEventAsGuest(eventId, token = null) {
    const guestAuthToken = token
      ? this.guestEventAuthorizationHeaderFromCookie(eventId, token)
      : await this.getGuestEventAuthHeaders(eventId)

    if (!guestAuthToken) {
      return Promise.reject(REJECT.UNAUTHORIZED)
    }

    return this.$apiService.instance
      .get(`/event/${eventId}`, {
        headers: {
          ...guestAuthToken,
        },
      })
      .then(response => mapEvent(response.data, this.config.filesPath))
  }

  /**
   * @param {string} eventId
   * @returns {undefined|string}
   */
  guestAuthTokenForEvent(eventId) {
    return this.$clientCookieService.get(`${GUEST_PARTY_AUTH_COOKIE_KEY}_${eventId}`)
  }

  /**
   * @param {string} guestAccessToken
   * @returns {Promise}
   */
  getGuestAuthToken(guestAccessToken) {
    return this.$apiService.instance
      .post(`/event/auth/guest`, { guestAccessToken })
      .then(response => {
        this.setGuestEventToken(response.data)

        return response.data.token
      })
  }

  /**
   * @param {string} eventAuthToken
   * @returns {void}
   */
  setGuestEventToken(eventAuthToken) {
    this.$clientCookieService
      .set(`${GUEST_PARTY_AUTH_COOKIE_KEY}_${eventAuthToken.id}`, eventAuthToken.token, { expires: 7 })
  }

  /**
   * @param {Date} date
   * @returns {number}
   */
  getSafeDateTimeEpochForEvent(date) {
    // Add 8 hours to prevent wrong date in different timezones
    // TODO: Make this based on timezones
    const newDate = date.setHours(date.getHours() + 8)

    return new Date(newDate).getTime()
  }

  /**
   * @param {string} eventId
   * @param {string|null} token
   * @returns {Object}
   */
  guestEventAuthorizationHeaderFromCookie(eventId, token = null) {
    let guestAuthToken = token ? token : this.guestStore.getGuestEventToken(eventId)

    if (!guestAuthToken) {
      guestAuthToken = this.$clientCookieService.get(`${GUEST_PARTY_AUTH_COOKIE_KEY}_${eventId}`)
    }

    return guestAuthToken ? { 'Event-Authorization': guestAuthToken } : undefined
  }

  async getGuestEventAuthHeaders(eventId) {
    let authHeaders = this.guestEventAuthorizationHeaderFromCookie(eventId)

    if (!authHeaders && this.route.query.auth) {
      await this.getGuestAuthToken(this.route.query.auth)
      authHeaders = this.guestEventAuthorizationHeaderFromCookie(eventId)
    }

    return authHeaders ?? null
  }
}
