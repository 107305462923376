import axios from 'axios'
import Compressor from 'compressorjs'

import { mapImages, mapGoogleImages, mapDropboxImages } from '~/lib/mappers/image-mapper'
import { EVENT_PROPERTY_KEY } from '~/lib/constants/event'
import { APP_MODAL, UPLOADING_IMAGES_MODAL } from '~/lib/constants/overlay'
import { MAX_UPLOAD_SIZE, PREMIUM_MAX_UPLOAD_SIZE, UPLOAD_STATES } from '~/lib/constants/upload-states'

import { useImagesStore } from '~/stores/images'
import { useToastStore } from '~/stores/toast'
import { CONNECTOR, CONNECTOR_ENDPOINT } from '~/lib/constants/connectors'
import { mapEventDownloads } from '~/lib/mappers/event-download-mapper'

/**
 * @typedef {ImagesService}
 * @alias this.$imagesService
 */
export class ImagesService {
  #currentPage
  #googleNextPageToken
  #dropboxNextPageToken

  constructor(nuxtApp) {
    this.nuxtApp = nuxtApp
    this.#currentPage = 0
    this.imagesStore = useImagesStore()
    this.toastStore = useToastStore()
    this.route = useRoute()
    this.config = nuxtApp.$config.public
    this.uploadDelay = 0
    this.backOffTime = 32000
    this.noNetworkBackOffTime = 120000
    this.noNetworkUploadDelay = 0
    this.downloadPollInterval = null
  }

  init() {
    this.$apiService = this.nuxtApp.$apiService
    this.$eventService = this.nuxtApp.$eventService
    this.$overlayService = this.nuxtApp.$overlayService
    this.$sentryService = this.nuxtApp.$sentryService
    this.$eventService = this.nuxtApp.$eventService
    this.$networkConnectionService = this.nuxtApp.$networkConnectionService
    this.$t = this.nuxtApp.$i18n.t
  }

  /**
   * @param {string} eventName
   * @param {momentshare.models.image.Image} image
   * @return {string}
   */
  mapFileNameForDownload(eventName, image) {
    return `${eventName.replace(/ /g, '-').toLowerCase()}-${image.id}.${this.mapExtension(image.mimeType)}`
  }

  async downloadFile(fileUrl, fileName, fileId, eventId, isGoogleVideo, isMomentshareDrive) {
    if (isGoogleVideo) {
      window.open(fileUrl)
      return
    }

    if (!isMomentshareDrive) {
      const file = await fetch(fileUrl)
      const fileBlob = await file.blob()
      this.createLinkAndDownloadBlobFile(fileBlob, fileName)

      return
    }

    return this.downloadImage(fileName, eventId, fileId)
  }

  /**
   * @param {string} fileName
   * @param {string} eventId
   * @param {string} imageId
   * @return {Promise<*>}
   */
  async downloadImage(fileName, eventId, imageId) {
    const authHeaders = await this.$eventService.getGuestEventAuthHeaders(eventId)

    return this.$apiService.instance.get(`/images/download/${eventId}/image/${imageId}`, {
        responseType: 'blob',
        headers: {
          ...(authHeaders && { ...authHeaders }),
        },
      }
    ).then((response) => {
      this.createLinkAndDownloadBlobFile(response.data, fileName)
    })
  }

  /**
   * @param {momentshare.models.event.Event} event
   * @param {Array<string>} imageIds
   * @return {Promise<*>}
   */
  async downloadImages(event, imageIds = []) {
    return this.$apiService.instance.post(`/images/download/from-event/${event.id}`, { imageIds })
  }

  /**
   * @param {string} path
   * @param {string} fileName
   * @return {void}
   */
  createLinkAndDownloadBlobFile(path, fileName) {
    const href = URL.createObjectURL(path)

    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()

    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  }

  /**
   * @param {string} eventId
   * @return {Promise}
   */
  getEventDownloads(eventId) {
    return this.$apiService.instance.get(`/images/download/download-status/${eventId}`).then(eventDownloads => mapEventDownloads(eventDownloads.data.eventDownloads))
  }


  /**
   * @param {momentshare.models.event.Event} event
   * @param {number} page
   * @param {'asc'|'desc'} [sort]
   * @param {'videos'|'images'} [filter]
   * @returns {Promise<void>}
   */
  async getImages({
    event,
    page = 0,
    sort,
    filter,
  }) {
    this.#currentPage = 0
    let images = []
    try {
      if (this.useGoogleDrive(event)) {
        this.#googleNextPageToken = null
        images = await this.fetchGoogleImages(event.id, sort)

        this.imagesStore.setHasMorePages(!!this.#googleNextPageToken)
      } else if (this.useDropbox(event)) {
        this.#dropboxNextPageToken = null
        const dropboxResult = await this.fetchDropboxImages(event.id)
        images = dropboxResult?.images

        this.imagesStore.setHasMorePages(!!dropboxResult?.hasMore)
      } else {
        const momentshareDriveResult = await this.fetchImages({ eventId: event.id, page, sort, filter })
        images = momentshareDriveResult.files

        this.imagesStore.setHasMorePages(momentshareDriveResult.hasMorePages)
      }

      this.imagesStore.setImages(images)
    } catch (error) {
      this.$sentryService.capture(error)
    }
  }

  /**
   * @param {momentshare.models.event.Event} event
   * @param {'asc'|'desc'} [sort]
   * @param {'videos'|'images'} [filter]
   * @returns {Promise<Array<Image>>}
   */
  async fetchNewPage({
    event,
    sort,
    filter,
  }) {
    const newPage = this.#currentPage + 1
    const currentImages = this.imagesStore.images
    let newImages = []
    try {
      if (this.useGoogleDrive(event)) {
        newImages = await this.fetchGoogleImages(event.id, sort)

        this.imagesStore.setHasMorePages(!!this.#googleNextPageToken)
      } else if (this.useDropbox(event)) {
        const dropboxResult = await this.fetchDropboxImages(event.id)
        newImages = dropboxResult?.images

        this.imagesStore.setHasMorePages(!!dropboxResult?.hasMore)
      } else {
        const momentshareDriveResult = await this.fetchImages({ eventId: event.id, page: newPage, sort, filter })
        newImages = momentshareDriveResult.files

        this.imagesStore.setHasMorePages(momentshareDriveResult.hasMorePages)
      }

      if (!newImages?.length) {
        this.imagesStore.setHasMorePages(false)
        return []
      }

      this.imagesStore.setImages([...currentImages, ...newImages])
      this.#currentPage = newPage

      return newImages
    } catch (error) {
      this.$sentryService.capture(error)
    }
  }

  /**
   * @param {string} eventId
   * @param {File[]} files
   * @param {boolean} largeVideosAllowed
   * @returns {Promise}
   */
  async uploadImages(eventId, files, largeVideosAllowed) {
    const uploadFileStates = this.mapFileAndState(files)

    this.$overlayService.setCurrentOverlay({
      settings: {
        type: APP_MODAL,
        component: UPLOADING_IMAGES_MODAL,
        options: { files: uploadFileStates },
      },
    })

    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileForm = new FormData()
        const isVideo = file.type.match('video.*')

        if (file.size > (largeVideosAllowed ? PREMIUM_MAX_UPLOAD_SIZE : MAX_UPLOAD_SIZE)) {
          const errorMessage = this.$t('file_too_large', { maxSize: largeVideosAllowed ? '2,5GB' : '250MB' })
          this.$overlayService.setCurrentOverlayOptions({ files: this.updateFileAndState(uploadFileStates, file, UPLOAD_STATES.OVERSIZED, 0, errorMessage) })
          continue
        }

        this.$overlayService.setCurrentOverlayOptions({ files: this.updateFileAndState(uploadFileStates, file, UPLOAD_STATES.UPLOADING, 0) })

        let compressedFile = file
        let convertedFile
        const isHeic = file.name.toLowerCase().includes('heic')

        if (isHeic) {
          convertedFile = await this.convertHeic(file)
        }

        if (!isVideo) {
          const fileToCompress = convertedFile ? convertedFile : file

          compressedFile = await this.compressFile(fileToCompress, 0.6)
            .catch(() => fileToCompress)
        }

        fileForm.set('file', compressedFile)

        await this.uploadImage({ eventId, fileForm, file, uploadFileStates })
      }
    } catch (error) {
      const errorMessage = error?.message ?? error
      this.$overlayService.setCurrentOverlayOptions({
        errorMessage,
      })
      this.$sentryService.capture(error)
      console.error(error)
    }
  }

  async uploadImage({ eventId, fileForm, file, uploadFileStates }) {
    return new Promise(async (resolve) => {
      const authHeaders = await this.$eventService.getGuestEventAuthHeaders(eventId)

      await this.$apiService.instance
        .post(
          `/images/${eventId}`,
          fileForm,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              ...(authHeaders && { ...authHeaders }),
            },
            onUploadProgress: progressEvent => {
              this.handleOnUploadProgressUpdated(progressEvent, uploadFileStates, file)
            },
          }
        )
        .then(() => {
          this.$overlayService.setCurrentOverlayOptions({ files: this.updateFileAndState(uploadFileStates, file, UPLOAD_STATES.DONE) })
          resolve()
        })
        .catch(error => {
          const errorMessage = error?.message ?? error

          if (errorMessage === 'Network Error' && !this.$networkConnectionService.isOnline) {
            const delay = 5000

            if (this.noNetworkUploadDelay >= this.noNetworkBackOffTime) {
              this.$overlayService.setCurrentOverlayOptions({
                files: this.updateFileAndState(uploadFileStates, file, UPLOAD_STATES.FAILED, 0, this.$t('network_error')),
              })
              this.$sentryService.capture(error)
            }

            this.$overlayService.setCurrentOverlayOptions({
              files: this.updateFileAndState(uploadFileStates, file, UPLOAD_STATES.UPLOADING, 0, this.$t('network_error_queued')),
            })

            setTimeout(async () => {
              this.noNetworkUploadDelay += delay

              this.uploadImage({ eventId, fileForm, file, uploadFileStates })
                .then(() => resolve())
            }, delay)
          } else {
            this.$overlayService.setCurrentOverlayOptions({
              files: this.updateFileAndState(uploadFileStates, file, UPLOAD_STATES.FAILED, 0, errorMessage),
            })
            resolve()
          }
        })
    })
  }

  /**
   * @param {string} eventId
   * @param {Array} images
   * @returns {Promise}
   */
  deleteImages(eventId, images) {
    return this.$apiService.instance.post(`/images/delete/from-event/${eventId}`, { imageIds: images })
  }

  /**
   * @param {string} eventId
   * @param {number} [page]
   * @param {'asc'|'desc'} [sort]
   * @param {'videos'|'images'} [filter]
   * @returns {Promise}
   */
  async fetchImages({
    eventId,
    page = 0,
    sort,
    filter,
  }) {
    const params = new URLSearchParams({
      ...sort && { sort },
      ...page && { page },
      ...filter && { filter },
    })
    const formattedSearchParams = params.toString()
    const formattedQueryParams = formattedSearchParams.length ? `?${formattedSearchParams}` : ''

    const authHeaders = await this.$eventService.getGuestEventAuthHeaders(eventId)

    return this.$apiService.instance
      .get(`/images/${eventId}${formattedQueryParams}`, {
        headers: {
          ...(authHeaders && { ...authHeaders }),
        },
      })
      .then(response => ({
        files: mapImages(response.data.files, this.config.filesPath),
        hasMorePages: response.data.hasMorePages,
      }))
  }

  /**
   * @param {string} eventId
   * @returns {Promise}
   */
  async fetchDropboxImages(eventId) {
    const params = new URLSearchParams({
      ...this.#dropboxNextPageToken && { page: this.#dropboxNextPageToken },
    })
    const formattedSearchParams = params.toString()
    const formattedQueryParams = formattedSearchParams.length ? `?${formattedSearchParams}` : ''

    const authHeaders = await this.$eventService.getGuestEventAuthHeaders(eventId)

    return this.$apiService.instance.get(`/images/dropbox/${eventId}/images${formattedQueryParams}`, {
      headers: {
        ...(authHeaders && { ...authHeaders }),
      },
    }).then(response => {
      this.#dropboxNextPageToken = response.data.cursor

      return { images: mapDropboxImages(response.data.entries), hasMore: response.data.has_more }
    }).catch(error => {
      this.toastStore.showToastMessage({
        text: error,
        secondary: true,
        duration: 10000,
      })
      this.$sentryService.capture(error)
    })
  }

  /**
   * @param {string} eventId
   * @param {'asc'|'desc'} [sort]
   * @returns {Promise}
   */
  async fetchGoogleImages(eventId, sort) {
    const params = new URLSearchParams({
      ...sort && { sort },
      ...this.#googleNextPageToken && { page: this.#googleNextPageToken },
    })
    const formattedSearchParams = params.toString()
    const formattedQueryParams = formattedSearchParams.length ? `?${formattedSearchParams}` : ''

    const authHeaders = await this.$eventService.getGuestEventAuthHeaders(eventId)

    return this.$apiService.instance.get(`/images/google/${eventId}/images${formattedQueryParams}`, {
      headers: {
        ...(authHeaders && { ...authHeaders }),
      },
    }).then(response => {
      this.#googleNextPageToken = response.data.nextPageToken

      return mapGoogleImages(response.data.files)
    }).catch(error => {
      this.toastStore.showToastMessage({
        text: error,
        secondary: true,
        duration: 10000,
      })
      this.$sentryService.capture(error)
    })
  }

  /**
   * @param {string} eventId
   * @param {boolean} shareable
   * @returns {Promise<string>}
   */
  createGoogleDriveFolder(eventId, shareable) {
    return this.$apiService.instance.post(`/images/google/${eventId}/create-event-folder`, { shareable })
  }

  /**
   * @param {string} eventId
   * @param {boolean} shareable
   * @returns {Promise<string>}
   */
  createDropboxFolder(eventId, shareable) {
    return this.$apiService.instance.post(`/images/dropbox/${eventId}/create-event-folder`, { shareable })
  }

  /**
   * @param {string} eventId
   * @param {File[]} files
   * @param {string} connector
   * @returns {Promise<Array>}
   */
  async getUploadLinks(eventId, files, connector) {
    return new Promise(async (resolve, reject) => {
      const connectorEndpoint = CONNECTOR_ENDPOINT[connector]
      let filesRequestObject = []

      for (let i = 0; i < files.length; i++) {
        const file = files[i]

        filesRequestObject.push({
          fileName: `${this.getFileId(file.lastModified)}_${file.name}`,
          mimeType: file.type,
          bytes: file.size,
        })
      }

      const authHeaders = await this.$eventService.getGuestEventAuthHeaders(eventId)

      return this.$apiService.instance.post(`/images/${connectorEndpoint}/${eventId}/resumable-upload-link`,
        filesRequestObject,
        {
          headers: {
            ...(authHeaders && { ...authHeaders }),
          },
        }).then(response => resolve(response.data))
        .catch(error => {
          if (error.message === 'Network Error' && !this.$networkConnectionService.isOnline) {
            const delay = 5000

            if (this.noNetworkUploadDelay >= this.noNetworkBackOffTime) return reject(this.$t('network_error'))

            setTimeout(async () => {
              this.noNetworkUploadDelay += delay

              this.getUploadLinks(eventId, files, connector)
                .then((data) => resolve(data))
                .catch(() => reject(this.$t('network_error')))
            }, delay)
          }
        })
    })
  }

  /**
   * @param {string} eventId
   * @param {File[]} files
   * @param {string} connector
   * @param {boolean} largeVideosAllowed
   * @returns {Promise}
   */
  async uploadImagesToConnector(eventId, files, connector, largeVideosAllowed) {
    const uploadFileStates = this.mapFileAndState(files)
    this.$overlayService.setCurrentOverlay({
      settings: {
        type: APP_MODAL,
        component: UPLOADING_IMAGES_MODAL,
        options: { files: uploadFileStates },
      },
    })

    try {
      this.uploadDelay = 1
      let allowedFiles = []

      for (const file of files) {
        if (file.size > (largeVideosAllowed ? PREMIUM_MAX_UPLOAD_SIZE : MAX_UPLOAD_SIZE)) {
          const errorMessage = this.$t('file_too_large', { maxSize: largeVideosAllowed ? '2,5GB' : '250MB' })
          this.$overlayService.setCurrentOverlayOptions({ files: this.updateFileAndState(uploadFileStates, file, UPLOAD_STATES.OVERSIZED, 0, errorMessage) })
        } else {
          allowedFiles.push(file)
        }
      }

      this.noNetworkUploadDelay = 0
      this.uploadDelay = 0
      const sessionUris = await this.getUploadLinks(eventId, allowedFiles, connector)

      for (const file of allowedFiles) {
        this.noNetworkUploadDelay = 0
        this.uploadDelay = 0
        const isVideo = file.type.match('video.*')

        const fileName = `${this.getFileId(file.lastModified)}_${file.name}`

        this.$overlayService.setCurrentOverlayOptions({ files: this.updateFileAndState(uploadFileStates, file, UPLOAD_STATES.UPLOADING, 0) })

        const sessionUri = sessionUris?.find(uri => uri.name === fileName)

        if (!sessionUri?.resumableSessionUri) {
          this.$overlayService.setCurrentOverlayOptions({ files: this.updateFileAndState(uploadFileStates, file, UPLOAD_STATES.FAILED) })
          continue
        }

        let compressedFile = file
        let convertedFile
        const isHeic = file.name.toLowerCase().includes('heic')

        if (isHeic) {
          convertedFile = await this.convertHeic(file)
        }

        if (!isVideo) {
          const fileToCompress = convertedFile ? convertedFile : file

          compressedFile = await this.compressFile(fileToCompress, 0.6)
            .catch(() => fileToCompress)
        }

        const continueUploadFile = await this.uploadFileToConnector(sessionUri.resumableSessionUri, compressedFile, connector, uploadFileStates)
          .then(() => {
            this.$overlayService.setCurrentOverlayOptions({ files: this.updateFileAndState(uploadFileStates, file, UPLOAD_STATES.DONE) })
            return true
          })
          .catch(error => {
            const errorMessage = error?.message ?? error
            this.$overlayService.setCurrentOverlayOptions({
              files: this.updateFileAndState(uploadFileStates, file, UPLOAD_STATES.FAILED, 0, errorMessage),
            })
            this.$sentryService.capture(error)

            return !(error.message === 'user_google_drive_storage_full' || error.message === 'user_dropbox_storage_full')
          })

        if (!continueUploadFile) {
          break
        }
      }
    } catch (error) {
      this.$overlayService.closeAppModal()
      this.$sentryService.capture(error)
      console.error(error)
    }
  }

  /**
   * @param {string} resumableSessionUri
   * @param {File|Blob} file
   * @param {string} connector
   * @param {Object} uploadFileStates
   * @returns {Promise<void>}
   */
  async uploadFileToConnector(resumableSessionUri, file, connector, uploadFileStates) {
    return new Promise(async (resolve, reject) => {
      try {
        if (connector === CONNECTOR.DROPBOX) {
          await axios.post(resumableSessionUri, file,
            {
              headers: {
                'Content-Type': 'application/octet-stream',
              },
              onUploadProgress: progressEvent => {
                this.handleOnUploadProgressUpdated(progressEvent, uploadFileStates, file)
              },
            })
          return resolve()
        }
        await axios.put(resumableSessionUri, file, {
          onUploadProgress: progressEvent => {
            this.handleOnUploadProgressUpdated(progressEvent, uploadFileStates, file)
          },
        })

        resolve()
      } catch (error) {
        this.handleConnectorUploadFileError({ error, resumableSessionUri, file, connector, uploadFileStates })
          .then(() => resolve())
          .catch(() => {
            this.$sentryService.capture(error)

            reject(error)
          })
      }
    })
  }

  handleConnectorUploadFileError({ error, resumableSessionUri, file, connector, uploadFileStates }) {
    return new Promise(async (resolve, reject) => {
      if (error.response?.status === 429) {
        //Hit rate limit https://developers.google.com/drive/api/guides/limits#exponential
        const delay = getRandomWaitTimeInMs()

        if (this.uploadDelay >= this.backOffTime) return reject(error)

        setTimeout(async () => {
          this.uploadDelay += delay

          return this.uploadFileToConnector(resumableSessionUri, file, connector, uploadFileStates)
            .then(() => resolve())
            .catch((error) => reject(error))
        }, delay)
      }

      if (error.response?.status === 403) {
        const errorReason = error.response?.data?.error?.errors?.[0]?.reason
        if (errorReason === 'storageQuotaExceeded') {
          return reject(new Error('user_google_drive_storage_full'))
        }
      }

      if (error.response?.data?.error_summary?.includes('storage_quota')) {
        return reject(new Error('user_dropbox_storage_full'))
      }

      if (error.response?.data?.error_summary) {
        return reject(error.response?.data?.error_summary)
      }

      if (error.message === 'Network Error' && !this.$networkConnectionService.isOnline) {
        const delay = 5000

        if (this.noNetworkUploadDelay >= this.noNetworkBackOffTime) {
          return reject(this.$t('network_error'))
        }

        this.$overlayService.setCurrentOverlayOptions({
          files: this.updateFileAndState(uploadFileStates, file, UPLOAD_STATES.UPLOADING, 0, this.$t('network_error_queued')),
        })

        setTimeout(async () => {
          this.noNetworkUploadDelay += delay

          return this.uploadFileToConnector(resumableSessionUri, file, connector, uploadFileStates)
            .then(() => resolve())
            .catch(() => reject(this.$t('network_error')))
        }, delay)
      }
    })
  }

  handleOnUploadProgressUpdated(progressEvent, uploadFileStates, file) {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    this.$overlayService.setCurrentOverlayOptions({ files: this.updateFileAndState(uploadFileStates, file, UPLOAD_STATES.UPLOADING, percentCompleted, null) })
  }

  /**
   * @param {File} file
   * @returns {Promise<Blob>}
   */
  async convertHeic(file) {
    const { default: heic2any } = await import('heic2any')

    let fileBlob = new Blob([file], { type: file.type })

    return await heic2any
      .default({
        blob: fileBlob,
        toType: 'image/jpeg',
        quality: 1,
      })
      .catch(() => {
        //if error let the api try handle the file
        return file
      })
  }

  /**
   * @param {File} file
   * @param {number} quality
   * @returns {Promise<Blob>}
   */
  compressFile(file, quality) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        quality,
        retainExif: true,
        success: resolve,
        error: reject,
      })
    })
  }

  /**
   * @param {number} id
   * @returns {string}
   */
  getFileId(id) {
    const idString = String(id)
    return idString.substring(idString.length - 5, idString.length)
  }

  /**
   * @param {momentshare.models.event.Event} event
   * @returns {boolean}
   */
  useGoogleDrive(event) {
    return !!this.$eventService.getEventPropertyByKey(event.properties, EVENT_PROPERTY_KEY.USE_GOOGLE_DRIVE)?.value
  }

  /**
   * @param {momentshare.models.event.Event} event
   * @returns {boolean}
   */
  useDropbox(event) {
    return !!this.$eventService.getEventPropertyByKey(event.properties, EVENT_PROPERTY_KEY.USE_DROPBOX)?.value
  }

  /**
   * @param {File[]} files
   * @returns {Array}
   */
  mapFileAndState(files) {
    let fileAndStates = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      fileAndStates.push({
        name: file.name,
        state: UPLOAD_STATES.QUEUE,
      })
    }

    return fileAndStates
  }

  updateFileAndState(fileAndStates, file, state, percentCompleted, errorMessage) {
    const fileName = file.name
    fileAndStates = fileAndStates.reduce((acc, fileState) => {
      if (fileState.name === fileName) {
        fileState.state = state
        fileState.percentageCompleted = percentCompleted
        fileState.errorMessage = errorMessage
      }

      acc.push(fileState)

      return acc
    }, [])

    return fileAndStates
  }

  /**
   * @param {string} mimeType
   * @return {string}
   */
  mapExtension(mimeType) {
    const mimeToExtMap = {
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'image/gif': 'gif',
      'image/bmp': 'bmp',
      'image/tiff': 'tiff',
      'video/mp4': 'mp4',
      'video/mpeg': 'mpeg',
      'video/webm': 'webm',
      'video/ogg': 'ogg',
      'video/quicktime': 'mov',
      'audio/mpeg': 'mp3',
      'audio/wav': 'wav',
    }

    const defaultExtension = 'unknown'

    return mimeToExtMap[mimeType] || defaultExtension
  }

  /**
   * @param {string}                            eventId
   * @param {momentshare.models.image.Image[]}  images
   */
  updateImageOrderIndices(eventId, images= []) {
    const mappedImages = images.map((image, index) => ({
      documentId: image.id,
      order: index,
    }))

    return this.$apiService.instance.post(`/images/order/${eventId}`, {
      orderIndices: mappedImages,
    })
  }

  resetImageSorting(eventId) {
    return this.$apiService.instance.post(`/images/order/${eventId}/reset`)
  }
}

function getRandomWaitTimeInMs() {
  return Math.max(1000, Math.floor(Math.random() * 4000))
}
