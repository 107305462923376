<template>
  <div class="error-page">
    <div class="error-page__state-wrapper">
      <error-state
        v-if="error.statusCode === 404"
        :title="$t('404_error_title')"
        :text="$t('404_error_text')"
        :button-text="$t('go_to_home')"
        @click="goToHome"
      />
      <error-state
        v-else
        :title="$t('error_state_title')"
        :text="$t('error_state_text')"
        :button-text="$t('go_to_home')"
        @click="goToHome"
      />
      <contact-options v-if="error.statusCode !== 404" class="error-page__state-contact" />
    </div>
  </div>
</template>

<script setup>
  const router = useRouter()
  const localePath = useLocalePath()

  const props = defineProps({
    error: {
      type: Object,
      required: true,
    },
  })

  onMounted(() => console.error(props.error?.message))

  function goToHome() {
    router.push(localePath('/'))
  }
</script>

<style lang="scss">
.error-page {
  padding: 0 var(--spacing-default);
}

.error-page__state-wrapper {
  margin: 164px auto;
}

.error-page__state-contact {
  margin-top: var(--spacing-larger);
}
</style>
