export const EVENT_PROPERTY_KEY = {
  FONT: 'font',
  UPLOAD_TEXT: 'uploadText',
  BACKGROUND_COLOR: 'backgroundColor',
  ENABLE_GALLERY: 'enableGallery',
  BACKGROUND_IMAGE_UPLOAD: 'backgroundImageUpload',
  BACKGROUND_IMAGE: 'backgroundImage',
  TEXT_COLOR: 'textColor',
  USE_GOOGLE_DRIVE: 'useGoogleDrive',
  HOSTING_ENABLED: 'hostingEnabled',
  USE_DROPBOX: 'useDropbox',
  LARGE_VIDEOS_ALLOWED: 'largeVideosAllowed',
}
